import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/Folder';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { listDir, newDir } from './fileslib';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import NewFolderDialog from './NewFolderDialog';


function pathEltsFromPath(path) {
  const pathEltStrs = path.split('/');
  return pathEltStrs.map((elt, i) => {
    return {name:elt, path:pathEltStrs.slice(0,i+1).join('/')};
  })
}

export default function MoveToSelector({ open, handleClose, anchorEl, user, token, moveFcn }) {

  const [ path, setPath ] = useState(user);
  const [ dirsAndFiles, setDirsAndFiles ] = useState([]);
  const [ selectedItem, setSelectedItem ] = useState(null);

  useEffect(() => {
    listDir(path, user, token, setDirsAndFiles);
  }, [path, user, token]);

  const pathElts = pathEltsFromPath(path);
  const pathEltsNm1 = pathElts.slice(0, -1);
  const lastPathElt = pathElts.at(-1);

  const navigateForward = (evt, dir) => {
    setPath(path + '/' + dir);
    setSelectedItem(null);
  }
  const navigateBackward = evt => {
    setPath(pathEltsNm1.at(-1).path);
    setSelectedItem(null);
  }

  const isSelected = (itemName) => {
    return selectedItem && selectedItem.name === itemName;
  }

  const [newFolderDialogOpen, setNewFolderDialogOpen] = useState(false);
  const newFolderClick = evt => {
    setNewFolderDialogOpen(true);
  }
  const handleNewFolderDialogClose = evt => {
    setNewFolderDialogOpen(false);
  }

  const doMove = evt => {
    moveFcn(path + '/' + selectedItem.name);
  }

  const id = open ? 'simple-popover' : undefined;

  return (
    <>
    <Popover id={id} open={open} onClose={handleClose} anchorEl={anchorEl}
             anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
             transformOrigin={{vertical: 'top', horizontal: 'right'}}
    >
      <AppBar sx={{ position: 'relative' }} color="inherit">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={navigateBackward}
            disabled={pathElts.length <= 1}
            aria-label="back"
          >
            <ArrowBackIosIcon fontSize="small" />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="subtitle1" component="div">
            {pathElts.length === 1 ? "My Files" : lastPathElt.name}
          </Typography>
          <IconButton
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <List dense={true}>
        {dirsAndFiles.map((dorf) => {
          const isRowSelected = isSelected(dorf.name);
          return (
          <ListItem
            key={dorf.name+'listitem'} 
            secondaryAction={
              dorf.type === 'dir' ? (
              <IconButton edge="end" aria-label="down" onClick={(evt) => navigateForward(evt, dorf.name)}>
                <ArrowForwardIosIcon fontSize="small" />
              </IconButton>)
              : null
              }
            disablePadding
          >
            <ListItemButton 
              disabled={dorf.type !== 'dir'}
              onClick={(event) => setSelectedItem(dorf)}
              selected={isRowSelected}
            >
              <ListItemIcon>
                {dorf.type === 'dir'
                  ? <FolderIcon fontSize="small" />
                  : <InsertDriveFileOutlinedIcon fontSize="small" />
                }
              </ListItemIcon>
              <ListItemText primary={dorf.name} />
            </ListItemButton>          
          </ListItem>
          );
        })}
      </List>

      <AppBar position="sticky" color="inherit" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <IconButton color="primary" aria-label="open drawer" onClick={newFolderClick}>
            <CreateNewFolderOutlinedIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Button color="primary" onClick={doMove}>
            Move
          </Button>
        </Toolbar>
      </AppBar>
    </Popover>

    <NewFolderDialog 
      open={newFolderDialogOpen} onClose={handleNewFolderDialogClose}
      path={path} user={user} token={token} setDirsAndFiles={setDirsAndFiles}
    />

    </>
  );
}
