import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const fileListNames = (fileList) => {
  const names = [];
  // loop through files
  for (let i = 0; i < fileList.length; i++) {
      names.push(fileList.item(i).name);
  }
  return names.join(', ');
}

export default function FileChooser({ open, handleClose, destinationDir, handleUpload }) {
  const destDirLabel = destinationDir === '~' ? "your home directory." : destinationDir.substring(2);

  const [uploadFiles, setUploadFiles] = useState(null);
  const [uploadFileNames, setUploadFileNames] = useState(null);

  const handleChange = (event) => {
    setUploadFiles(event.target.files);
    setUploadFileNames(fileListNames(event.target.files));
  }

  const doUpload = (event) => {
    handleUpload(uploadFiles);
    doClose();
  }

  const doClose = () => {
    setUploadFiles(null);
    handleClose();
  }

  return (
    <div>
      <Dialog open={open} onClose={doClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Select file(s) for upload</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            The file(s) you select will be uploaded to {destDirLabel}
          </DialogContentText>
          <input
            style={{display: 'none'}}
            id="file-upload-input"
            multiple
            type="file"
            onChange={handleChange}
          />
          <label htmlFor="file-upload-input">
            <Button variant="contained" color="primary" component="span">
              Select file(s)
            </Button>
          </label>
          {uploadFiles ? 
            <>
            <DialogContentText>
              <p>{uploadFileNames}</p>
            </DialogContentText>
            </>
          : null }
        </DialogContent>
        <DialogActions>
          <Button onClick={doClose} color="primary">
            Cancel
          </Button>
          <Button onClick={doUpload} color="primary" disabled={uploadFiles == null}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
