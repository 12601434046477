import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


export default function TopBar( { user } ) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ backgroundColor: '#A82040' }}>
        <Toolbar>
          <Box component="span" sx={{ mr: 2 }}><img src="/mvl-logo.png" alt="Manhattanville logo"/></Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Mville Data Science &mdash; Files
          </Typography>
          { user
            ? <Button color="inherit">Signed in as {user}</Button>
            : null
          }
        </Toolbar>
      </AppBar>
      {/* Make an offset to push the content down because of the fixed AppBar */}
      <Toolbar /> 
    </Box>
  );
}
