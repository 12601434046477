import cookie from 'cookie';

// one week in seconds
export const ONE_WEEK = 24 * 60 * 60 * 7;

/**
 * Stores a cookie which will expire after maxAge seconds. If no maxAge is defined
 * the cookie will persist for the duration of the browser session.
 * @param {string} name
 * @param {string} value
 * @param {number} maxAge - time until expiry in seconds
 */
export const createCookie = (name, value, maxAge) => {
  if (typeof window !== 'undefined') {

    const cookies = cookie.serialize(name, value, {
      path: '/',
      maxAge,
      // domain: 'onlineclasstools.com',
    });
    document.cookie = cookies;
  }
};


/**
 * Returns the value of the cookie keyed by the supplied name
 * @param {string} name
 * @returns {string}
 */
export const readCookie = (name) => {
  if (typeof window !== 'undefined') {
    const cookies = cookie.parse(document.cookie);
    return cookies[name];
  }
};

/**
 * Deletes a cookie with the supplied name by setting the value to ''
 * and the maxAge to zero
 * @param {string} name
 */
export const eraseCookie = (name) => {
  createCookie(name, undefined, 0);
};
