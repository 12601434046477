import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
// import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { listDir, deleteFileOrDir, uploadFiles, downloadFile, renameFileOrDir, moveFileOrDir } from './fileslib';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FileChooser from './FileChooser';
import MoveToSelector from './MoveToSelector';
import NewFolderDialog from './NewFolderDialog';


function pathEltsFromPath(path) {
  const pathEltStrs = path.split('/');
  return pathEltStrs.map((elt, i) => {
    return {name:elt, path:pathEltStrs.slice(0,i+1).join('/')};
  })
}

export default function DirView({ user, token }) {

  const [ path, setPath ] = useState(user);
  const [ dirsAndFiles, setDirsAndFiles ] = useState([]);
  const [ selectedItem, setSelectedItem ] = useState(null);
  const [ version, setVersion ] = useState(0);

  const refreshFiles = () => {
    setVersion(version + 1);
  }

  useEffect(() => {
    listDir(path, user, token, setDirsAndFiles);
  }, [path, user, token]);

  const pathElts = pathEltsFromPath(path);
  const pathEltsNm1 = pathElts.slice(0, -1);
  const lastPathElt = pathElts.at(-1);

  const navigateForward = (event, dir) => {
    event.stopPropagation();
    setPath(path + '/' + dir);
    setSelectedItem(null);
  }

  const navigateBackward = (event, backPath) => {
    setPath(backPath);
    setSelectedItem(null);
  }

  // window resizing solution courtesy of:
  // https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }
    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  })

  // kind of a hack -- i have to hand-calculate the height of the appbar and breadcrumbs header
  const tableHeight = dimensions.height - 120;

  const [anchorElMore, setAnchorElMore] = useState(null);
  const openMore = Boolean(anchorElMore);
  const handleMoreMenuClick = (event) => {
    setAnchorElMore(event.currentTarget);
  };
  const handleMoreMenuClose = () => {
    setAnchorElMore(null);
  };

  const isSelected = (itemName) => {
    return selectedItem && selectedItem.name === itemName;
  }

  const [anchorElAdd, setAnchorElAdd] = useState(null);
  const openAdd = Boolean(anchorElAdd);
  const handleAddMenuClick = (event, item) => {
    setAnchorElAdd(event.currentTarget);
  };
  const handleAddMenuClose = () => {
    setAnchorElAdd(null);
  };

  const [newFolderDialogOpen, setNewFolderDialogOpen] = useState(false);
  const newFolderClick = evt => {
    setNewFolderDialogOpen(true);
    setAnchorElAdd(null);
  }
  const handleNewFolderDialogClose = evt => {
    setNewFolderDialogOpen(false);
  }

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const handleDeleteClick = evt => {
    setConfirmDeleteDialogOpen(true);
  }
  const handleConfirmDeleteDialogClose = evt => {
    setConfirmDeleteDialogOpen(false);
  }
  const doDeleteFileOrFolder = evt => {
    deleteFileOrDir(selectedItem.name, path, user, token, setDirsAndFiles);
    setConfirmDeleteDialogOpen(false);
    setSelectedItem(null);
  }

  const handleDownloadClick = evt => {
    handleMoreMenuClose();
    if (selectedItem && selectedItem.type === 'file') {
      const targetFile = path + '/' + selectedItem.name;
      downloadFile(targetFile, user, token);
    }
  }

  const openUploadDialog = (event) => {
    const upldDir = path.replace(user, '~');
    setUploadDir(upldDir);
    setFileChooserOpen(true);
    setAnchorElAdd(null);
  }
  const uploadClosed = () => {
    setFileChooserOpen(false);
    refreshFiles();
  }
  const doFileUpload = (files) => {
    uploadFiles(uploadDir, files, user, token, setDirsAndFiles);
  }
  const [fileChooserOpen, setFileChooserOpen] = useState(false);
  const [uploadDir, setUploadDir] = useState('~');

  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [renameName, setRenameName] = useState('');
  const handleRenameClick = evt => {
    setRenameName(selectedItem.name);
    setRenameDialogOpen(true);
    setAnchorElMore(null);
  }
  const handleRenameDialogClose = evt => {
    setRenameDialogOpen(false);
  }
  const submitRename = evt => {
    const targetFile = path + '/' + selectedItem.name;
    renameFileOrDir(renameName, targetFile, user, token, setDirsAndFiles);
    setRenameDialogOpen(false);
  }
  const handleRenameChange = (event) => {
    setRenameName(event.target.value);
  };

  const [ moveToOpen, setMoveToOpen ] = useState(false);
  const [ anchorElMove, setAnchorElMove ] = useState(null);
  const moveToClosed = evt => {
    setMoveToOpen(false);
  }
  const handleMoveToClick = evt => {
    setAnchorElMove(anchorElMore);
    handleMoreMenuClose();
    setMoveToOpen(true);
  }
  const handleMoveTo = (new_path) => {
    const targetFile = path + '/' + selectedItem.name;
    moveFileOrDir(new_path, targetFile, user, token, setDirsAndFiles);
    setMoveToOpen(false);    
  }

  return (
    <>
    <Box position="fixed" sx={{ width: '100%', p:2, backgroundColor: 'grey.300', 
                                display: 'flex', justifyContent: 'space-between' }}>
      <Breadcrumbs sx={{ pt:1 }} aria-label="breadcrumb">
        { pathEltsNm1.map(pathElt => (
            <Link component="button" variant="body1" key={pathElt.path}
              onClick={(evt) => navigateBackward(evt, pathElt.path)}
            >
              {pathElt.name}
            </Link>
          ))
        } 
        <Typography color="text.primary">{lastPathElt.name}</Typography>
      </Breadcrumbs>
      <Box sx={{ paddingRight: '32px' }}>
        <IconButton aria-label="add" color="primary" onClick={handleAddMenuClick}>
          <AddCircleIcon />
        </IconButton>
        <IconButton aria-label="delete" color="primary" onClick={handleDeleteClick} disabled={selectedItem === null}>
          <DeleteIcon />
        </IconButton>
        <IconButton aria-label="more options" color="primary" onClick={handleMoreMenuClick} disabled={selectedItem === null}>
          <MoreVertIcon />
        </IconButton>
      </Box>
    </Box>
    {/* This box is here just to push content down, because of position-fixed in box above */}
    <Box sx={{ p:3 }}>&nbsp;</Box>
    <Divider />
    {/* </Paper> */}

    {/* <Fab color="primary" aria-label="add" sx={{ position: 'absolute', zIndex: 1000, top: 75, right: 20 }}
        onClick={handleAddMenuClick}>
      <AddIcon />
    </Fab> */}

    <Paper sx={{ width: '100%', height: tableHeight, overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: '100%' }}>
        <Table aria-label="file table" size="small" stickyHeader>
          <TableHead>
            <TableRow onClick={evt => setSelectedItem(null)}>
              <TableCell padding="checkbox">Name</TableCell>
              <TableCell>&nbsp;</TableCell>
              {/* <TableCell padding="checkbox">&nbsp;</TableCell> */}
              <TableCell>Last Modified</TableCell>
              <TableCell>Size</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dirsAndFiles.map((dorf) => {
              const isRowSelected = isSelected(dorf.name);
              return (
                <TableRow
                  key={dorf.name}
                  hover
                  aria-checked={isRowSelected}
                  role="checkbox"
                  tabIndex={-1}
                  selected={isRowSelected}
                  onClick={(event) => setSelectedItem(dorf)}
                  sx={{ 
                    '&:last-child td, &:last-child th': { border: 0 },
                    "& .hidden-button": {
                      display: "none"
                    },
                    "&:hover .hidden-button": {
                      display: "flex"
                    }          
                  }}
                >
                  <TableCell padding="checkbox">
                    {dorf.type === 'dir'
                      ? <FolderIcon />
                      : <InsertDriveFileOutlinedIcon />
                    }
                  </TableCell>
                  <TableCell>
                    {dorf.type === 'dir'
                    ? (
                      <Link component="button" variant="body2"
                        onClick={(evt) => navigateForward(evt, dorf.name)}
                      >
                        {dorf.name}
                      </Link>
                      )
                    : dorf.name
                    }
                  </TableCell>
                  {/* <TableCell padding="checkbox">
                    <IconButton aria-label="delete" className="hidden-button" 
                      onClick={evt => handleMoreMenuClick(evt, dorf)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell> */}
                  <TableCell>{dorf.ts}</TableCell>
                  <TableCell>
                    {dorf.type === 'dir'
                      ? <span>&mdash;</span>
                      : dorf.size
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>

    <Menu
      id="filedir-menu"
      anchorEl={anchorElMore}
      open={openMore}
      onClose={handleMoreMenuClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={handleDownloadClick}>
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText>Download</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleMoveToClick}>
        <ListItemIcon>
          <DriveFileMoveIcon />
        </ListItemIcon>
        <ListItemText>Move to</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleRenameClick}>
        <ListItemIcon>
          <DriveFileRenameOutlineIcon />
        </ListItemIcon>
        <ListItemText>Rename</ListItemText>
      </MenuItem>
    </Menu>

    <Menu
      id="newupload-menu"
      anchorEl={anchorElAdd}
      open={openAdd}
      onClose={handleAddMenuClose}
    >
      <MenuItem onClick={newFolderClick}>
        <ListItemIcon>
          <CreateNewFolderOutlinedIcon />
        </ListItemIcon>
        <ListItemText>Folder</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={openUploadDialog}>
        <ListItemIcon>
          <UploadFileOutlinedIcon />
        </ListItemIcon>
        <ListItemText>File Upload</ListItemText>
      </MenuItem>
      {/* <MenuItem onClick={handleAddMenuClose}>
        <ListItemIcon>
          <DriveFolderUploadOutlinedIcon />
        </ListItemIcon>
        <ListItemText>Folder Upload</ListItemText>
      </MenuItem> */}
    </Menu>

    <NewFolderDialog 
      open={newFolderDialogOpen} onClose={handleNewFolderDialogClose}
      path={path} user={user} token={token} setDirsAndFiles={setDirsAndFiles}
    />

    <Dialog open={renameDialogOpen} onClose={handleRenameDialogClose}>
      <DialogTitle>Rename File or Folder</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the new name for the file or folder.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="File/Folder Name"
          type="text"
          fullWidth
          variant="standard"
          value={renameName}
          onChange={handleRenameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRenameDialogClose}>Cancel</Button>
        <Button onClick={submitRename}>Rename</Button>
      </DialogActions>
    </Dialog>

    <Dialog
        open={confirmDeleteDialogOpen}
        onClose={handleConfirmDeleteDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Delete ${selectedItem ? selectedItem.name : ''}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteDialogClose}>Cancel</Button>
          <Button onClick={doDeleteFileOrFolder} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <FileChooser open={fileChooserOpen} handleClose={uploadClosed}
                   destinationDir={uploadDir} handleUpload={doFileUpload} />
      <MoveToSelector open={moveToOpen} handleClose={moveToClosed} 
                      anchorEl={anchorElMove} user={user} token={token} moveFcn={handleMoveTo}/>

    </>
  );
}
