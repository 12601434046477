
function pathMinusUser(path, user) {
    // if (path.length === user.length) {
    //   return "";
    // } else {
    //   return path.slice(user.length + 1);
    // }
    return '~' + path.substring(user.length);
}  

function nameSort(f1, f2) {
    const n1 = f1.name;
    const n2 = f2.name;
    if (n1 < n2) {
        return -1;
    }
    if (n1 > n2) {
        return 1;
    }
    return 0;
}

function postProcessFilesResponse(responseObj) {
    const dirs = responseObj.files.filter(file => file.type === 'dir');
    const files = responseObj.files.filter(file => file.type === 'file');
    dirs.sort(nameSort);
    files.sort(nameSort);
    return dirs.concat(files)
}
  
export function listDir(path, user, token, receiveListing) {
    fetch('api/files/'+ pathMinusUser(path, user), {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
    .then(response => response.json())
    .then(responseObj => {
        receiveListing(postProcessFilesResponse(responseObj));
    });
}

export function newDir(name, path, user, token, receiveListing) {
    const name2 = name.trim();
    fetch('api/files/'+ pathMinusUser(path, user) + '/' + name2, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
    .then(response => response.json())
    .then(responseObj => {
        if (responseObj.hasOwnProperty('files')) {
            receiveListing(postProcessFilesResponse(responseObj));
        } else {
            alert(responseObj.detail);
        }
    });
}

export function deleteFileOrDir(name, path, user, token, receiveListing) {
    fetch('api/files/'+ pathMinusUser(path, user) + '/' + name, {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
    .then(response => response.json())
    .then(responseObj => {
        receiveListing(postProcessFilesResponse(responseObj));
    });
}

export function uploadFiles(path, files, user, token, receiveListing) {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('dir', path);
    return fetch( 'api/upload', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token
        },
        body: formData
    })
    .then((response) => response.json())
    .then(responseObj => {
        receiveListing(postProcessFilesResponse(responseObj));
    });
}

export function renameFileOrDir(new_name, path, user, token, receiveListing) {
    const new_path_arr = path.split('/').slice(0, -1);
    const new_name2 = new_name.trim();
    new_path_arr.push(new_name2);
    const new_path = pathMinusUser(new_path_arr.join('/'), user);
    fetch('api/files/'+ pathMinusUser(path, user), {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({path:new_path, is_move:false})
    })
    .then(response => response.json())
    .then(responseObj => {
        receiveListing(postProcessFilesResponse(responseObj));
    });
}

export function moveFileOrDir(moveto_path, path, user, token, receiveListing) {
    const filename = path.split('/').at(-1);
    const new_path = pathMinusUser(moveto_path, user);
    fetch('api/files/'+ pathMinusUser(path, user), {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({path:new_path + '/' + filename, is_move:true})
    })
    .then(response => response.json())
    .then(responseObj => {
        receiveListing(postProcessFilesResponse(responseObj));
    });
}

export function downloadFile(path, user, token) {
    // console.log(path);
    // console.log(pathMinusUser(path, user).substring(2));
    const filename = pathMinusUser(path, user).substring(2);
    fetch('api/download?file=' + encodeURIComponent(filename), {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
    .then(response => response.blob())
    .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = path.split('/').at(-1);
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    })
    .catch(err => {
        console.log(err);
    });
}