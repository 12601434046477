import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TopBar from './TopBar';
import DirView from './DirView';
import SignIn from './SignIn';
import { readCookie } from './cookies';


function App() {

  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const cookieUser = readCookie('Username');
    const cookieToken = readCookie('Authorization');
    if (cookieUser && cookieToken) {
      setUser(cookieUser);
      setToken(cookieToken);
    } else {
      console.log('user needs to sign in');
    }  
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <TopBar user={user} />
      {user && token
       ? <DirView user={user} token={token} />
       : <SignIn setUserFcn={setUser} setTokenFcn={setToken} />
      }
    </Box>
  );
}

export default App;
