import React, { useState } from 'react';
import { newDir } from './fileslib';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function NewFolderDialog({ open, onClose, path, user, token, setDirsAndFiles }) {

  const [newFolderName, setNewFolderName] = useState('');
  const submitNewFolder = evt => {
    if (newFolderName.search(/[^A-Za-z0-9-_. !~*'()]/) != -1) {
      alert('"' + newFolderName + '" has characters that are not allowed in folder names');
      return;
    }
    newDir(newFolderName.replaceAll(' ', '+'), path, user, token, setDirsAndFiles);
    handleClose();
  }
  const handleNewFolderChange = (event) => {
    setNewFolderName(event.target.value);
  };
  const handleClose = (evt) => {
    setNewFolderName('');
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>New Folder</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter the name of the new folder.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Folder Name"
          type="text"
          fullWidth
          variant="standard"
          value={newFolderName}
          onChange={handleNewFolderChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={submitNewFolder}>Create</Button>
      </DialogActions>
    </Dialog>

  )
}
